import forest from "./assets/forest.jpg";

const mockupNews = [
  {
    id: 1,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 2,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 3,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 4,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 5,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 6,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 7,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 8,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 9,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 10,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
  {
    id: 11,
    img: forest,
    title: "Женщина бросила работу ради сбора грибов и обрела душевный покой",
    date: "18:33, 18 ноября 2022",
  },
];

export default mockupNews;
